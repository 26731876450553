import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "../components/block-content";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FaLinkedin, FaCheck } from "react-icons/fa"

export const query = graphql`
query AttorneyTemplateQuery($id: String = "") {
  atty:sanityAuthor(id: {eq: $id}) {
      slug {
      current
    }
    id
    _rawBio
    _updatedAt
    job_title
    name
    key_facts
    linkedin
    image {
      asset {
        gatsbyImageData(height: 400, width: 400, placeholder: BLURRED)
      }
    }
    seo {
      title
      description
      image {
        asset {
          url
        }
      }
    }
  }
}

`
const Attorney = props => {

  const { data } = props;
  const atty = (data || {}).atty || {};

  return (
    <Layout title={atty.seo?.title || atty.name} description={atty.seo?.description} image={atty.seo?.image} location={"/attorneys/" + atty.slug.current}>
      <div className="flex flex-wrap pb-16 md:pt-6 px-4 md:px-16 max-w-7xl mx-auto">
        <div className="w-full lg:w-2/5 px-0 md:px-6 mb-8">
          <div className="contact-card bg-gray-100 border border-gray-300 py-8 px-6 relative">
            <div className="left-0 right-0 text-center">
              <GatsbyImage className="rounded-lg" image={getImage(atty.image.asset)} alt={atty.image.alt} /></div>
            <h1 className="pt-4 text-4xl">{atty.name}</h1>
            <h2 className="text-gray-600 text-xl">{atty.job_title}</h2>

            <div className="border block border-black border-opacity-10 mt-6 mb-4"></div>
            <div className="key_facts mt-6">
              {atty.key_facts && atty.key_facts.map((fact, i) => (
                <div key={i} className="text-lg leading-xl">
                  <div className="flex items-center mb-4">
                    <FaCheck className="inline mr-4 text-green-600" /> <div>{fact}</div>
                  </div>
                </div>
              ))}
            </div>
            <Link to="/contact" className="btn btn-purple mt-8 block min-w-full text-center">Contact Me</Link>
            <a href={atty.linkedin} target="_blank" rel="noreferrer" className="btn linkedin  block min-w-full text-center">
              <FaLinkedin className="inline mr-2 -mt-1" />
              LinkedIn
            </a>
          </div>
        </div>
        <div className="w-full lg:w-3/5 px-6">
          {atty._rawBio && <BlockContent blocks={atty._rawBio || []} />}

        </div>
      </div>

    </Layout>
  );
}

export default Attorney